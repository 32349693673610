
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const appMenu = computed(() => {
      const menuAdmin = ref([
        {
          label:'Gửi tin nhắn',
          icon:'pi pi-fw pi-send',
          to: '/sendmessage'
        },
        {
          label:'Đăng hình ảnh',
          icon:'pi pi-fw pi-upload',
          to: '/uploadImage'
        },
        // {
        //   label:'Thuế',
        //   icon:'pi pi-fw pi-dollar',
        //   to: '/addtax'
        // },
        {
          label:'DS dân cư trong tổ',
          icon:'pi pi-fw pi-bars',
          to: '/residentList'
        },
        {
          label:'Quản lý nhóm',
          icon:'pi pi-fw pi-plus-circle',
          to: '/managerGroup'
        },
        {
          label:'Quản lý nhận góp ý',
          icon:'pi pi-fw pi-envelope',
          to: '/addManagerFeedbackPage'
        },
        // {
        //   label:'Quản lý lịch trực',
        //   icon:'pi pi-fw pi-calendar',
        //   to: '/fullcalendar'
        // },
        {
          label:'Đăng ký tài khoản',
          icon:'pi pi-fw pi-user-plus',
          to: '/register'
        },
        {
          label:'Quản lý tài khoản',
          icon:'pi pi-fw pi-users',
          to: '/userlist'
        },
      ]);
      const menuDefault = ref([
      ]);
      const menuUser = ref([
        {
          label:'Gửi tin nhắn',
          icon:'pi pi-fw pi-send',
          to: '/sendmessage'
        },
        {
          label:'Đăng hình ảnh',
          icon:'pi pi-fw pi-upload',
          to: '/uploadImage'
        },
        {
          label:'Quản lý nhóm',
          icon:'pi pi-fw pi-plus-circle',
          to: '/managerGroup'
        },
        // {
        //   label:'Thuế',
        //   icon:'pi pi-fw pi-dollar',
        //   to: '/addtax'
        // },
        {
          label:'DS dân cư trong tổ',
          icon:'pi pi-fw pi-bars',
          to: '/residentList'
        },
      ]);
      const menu = ref([
        {
          label:'Gửi tin nhắn',
          icon:'pi pi-fw pi-send',
          to: '/sendmessage'
        },
        {
          label:'Đăng hình ảnh',
          icon:'pi pi-fw pi-upload',
          to: '/uploadImage'
        },
        {
          label:'Quản lý nhóm',
          icon:'pi pi-fw pi-plus-circle',
          to: '/managerGroup'
        },
        {
          label:'DS dân cư trong tổ',
          icon:'pi pi-fw pi-bars',
          to: '/residentList'
        },
      ]);
      if(!!store.state.token) {
        console.log("@@@@@@@######################## PERMISSION: " + store.state.permission);
        if(store.state.permission == "admin")
          return menuAdmin.value;
        else if((store.state.permission == "ward")||(store.state.permission == "district"))
          return menuUser.value;
        else
          return menu.value;
      }
      else
        return menuDefault.value;
    });
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }
    const gotoLoginPage = () => {
      router.push({
        name: 'login',
      });
    }

    return {
      appMenu,
      logout,
      gotoLoginPage
    }
  }
}
