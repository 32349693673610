

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {ListCustomer} from "@/models/listCustomer";
import ZaloRepository from "@/services/ZaloRepository";
import {EmployeeCreate} from "@/models/employeeCreate";
import zaloRepository from "@/services/ZaloRepository";
import {GroupCreate} from "@/models/groupCreate";
import teamRepository from "../services/TeamRepository";
import {Group} from "@/models/group";
import {MemberInGroupCreate} from "@/models/memberInGroupCreate";
import {MemberInGroup} from "@/models/memberInGroup";
import {ManagerFeedbackCreate} from "@/models/managerFeedbackCreate";
import {ManagerFeedback} from "@/models/managerFeedback";
import {FilterMatchMode} from "primevue/api";
import {ManagerCheckinCreate} from "@/models/managerCheckinCreate";
import {ManagerCheckin} from "@/models/managerCheckin";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listCustomer = ref([] as ListCustomer[]);
    const managerCheckin = ref({} as ManagerCheckinCreate);
    const listManagerCheckin = ref([] as ManagerCheckin[]);

    const loadListMember = () => {
      zaloRepository.getListCustomer()
          .then((response) => {
            listCustomer.value = response.data;
            loadListManager();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách công dân',
              life: 2000
            });
          });
    }

    loadListMember();

    const loadListManager = () => {
      zaloRepository.getListManagerOfCheckin()
          .then((response) => {
            listManagerCheckin.value = response.data;
            listManagerCheckin.value.forEach(x => {
              listCustomer.value.filter(y => {
                if(y.id == x.zaloId) x.imageLink = y.imageUrl;
              })
            });
            console.log("@@################### loadListManager listManagerCheckin : " + JSON.stringify(listManagerCheckin.value))
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách nhận góp ý',
              life: 2000
            });
          });
    }



    const addMember = () => {
      listCustomer.value.forEach(x => {
        if(x.id == managerCheckin.value.zaloId){
          managerCheckin.value.fullName = x.name;
        }
      })
      zaloRepository.addManagerOfCheckin(managerCheckin.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm thành viên vào nhóm thành công',
              life: 2000
            });
            loadListManager();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteManager = (id: number) => {
      zaloRepository.deleteManagerOfCheckin(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xóa thành viên ra khỏi nhóm thành công',
              life: 2000
            });
            loadListManager();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      error,
      managerCheckin,
      listCustomer,
      addMember,
      deleteManager,
      listManagerCheckin,
      filters,
      clearFilter,
      initFilters,
    }
  }
}

