import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import SendMessagePage from "@/pages/SendMessagePage.vue";
import UploadImage from "@/pages/UploadImage.vue";
import RegisterPage from "@/pages/RegisterPage.vue";
import UserList from "@/pages/UserList.vue";
import UserUpdatePage from "@/pages/UserUpdatePage.vue";
import AddTax from "@/pages/AddTax.vue";
import addGroupAndMemberPage from "@/pages/AddGroupAndMemberPage.vue";
import residentList from "@/pages/ResidentList.vue";
import residentUpdatePage from "@/pages/ResidentUpdatePage.vue";
import addManagerFeedbackPage from "@/pages/AddManagerFeedbackPage.vue";
import FullCalendar from "@/pages/FullCalendar.vue";
import addManagerCheckinPage from "@/pages/AddManagerCheckinPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage,
    props: true
  },
  {
    path: '/residentList',
    name: 'residentList',
    component: residentList,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/sendmessage',
    name: 'sendmessage',
    component: SendMessagePage,
    props: true
  },
  {
    path: '/uploadImage',
    name: 'uploadImage',
    component: UploadImage,
    props: true
  },
  {
    path: '/managerGroup',
    name: 'managerGroup',
    component: addGroupAndMemberPage,
    props: true
  },
  {
    path: '/userList',
    name: 'userList',
    component: UserList,
    props: true
  },
  {
    path: '/addTax',
    name: 'addTax',
    component: AddTax,
    props: true
  },
  {
    path: '/addManagerFeedbackPage',
    name: 'addManagerFeedbackPage',
    component: addManagerFeedbackPage,
    props: true
  },
  {
    path: '/addManagerCheckinPage',
    name: 'addManagerCheckinPage',
    component: addManagerCheckinPage,
    props: true
  },
  {
    path: '/fullCalendar',
    name: 'fullCalendar',
    component: FullCalendar,
    props: true
  },
  {
    path: '/userUpdate/:id',
    name: 'userUpdate',
    component: UserUpdatePage,
    props: true
  },
  {
    path: '/residentUpdatePage/:id',
    name: 'residentUpdatePage',
    component: residentUpdatePage,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
