
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {ref} from "vue";
import {WorkSchedule} from "@/models/workSchedule";
import zaloRepository from "@/services/ZaloRepository";
import {useToast} from "primevue/usetoast";
import {ListCustomer} from "@/models/listCustomer";
import {FilterMatchMode} from "primevue/api";
import {WorkScheduleDetail} from "@/models/workScheduleDetail";
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Calendar } from '@fullcalendar/core';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import viLocale from '@fullcalendar/core/locales/vi';


export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  setup() {
    const listWorkSchedule = ref([] as WorkSchedule[]);
    const listSelect = ref([] as WorkScheduleDetail[]);
    const toast = useToast();
    const workSchedule = ref({} as WorkSchedule);
    const date = ref();
    const checkSelect = ref(false);
    const customer = ref({} as ListCustomer);
    const dateString = ref("");

    const formatDateTime = (date) => {
      return moment(String(date)).format('DD/MM/YYYY');
    };
    const handleDateClick = (arg) => {
      checkSelect.value = true;
      console.log("@@@@@############ debug handleDateClick: " + arg.dateStr);
      listSelect.value = [];

      listWorkSchedule.value.forEach(x => {
        if(x.timeString == arg.dateStr)
        {
          const workScheduleDetail = ref({} as WorkScheduleDetail);
          workScheduleDetail.value.fullName = x.fullName;
          workScheduleDetail.value.id = x.id;
          listCustomer.value.forEach(y => {
            if(y.id == x.zaloId)  workScheduleDetail.value.imageLink = y.imageUrl;
          });
          listSelect.value.push(workScheduleDetail.value);
        }
      });
      date.value = arg.dateStr;
      dateString.value = "Ngày " + arg.dateStr.split("-")[2] + "/" + arg.dateStr.split("-")[1] + "/" + arg.dateStr.split("-")[0] ;
      console.log("@@@@@############ debug date: " + date.value);
    }

    const eventDropNow= () => {
      console.log("@@@@@############ debug eventDrop: ");
    }

    const initEvent = ($event) =>
    {
      console.log("@@@@@############ initEvent: ");
      return null;
    }
    const calendarOptions = ref({
      plugins: [ dayGridPlugin, interactionPlugin, bootstrap5Plugin ],
      initialView: 'dayGridMonth',
      dateClick: handleDateClick,
      events: [{ title: "", date: "" }],
      //initialEvents: initEvent,
      selectable: true,
      themeSystem: 'bootstrap5',
      // timeZone: 'UTC',
      timeZone: 'Asia/Ho_Chi_Minh',
      locale: 'vi',
      locales: [ viLocale],
      // end: 'today prev,next',
      headerToolbar: {
        today:    'today',
        month:    'month',
        week:     'week',
        day:      'day',
        list:     'list'
      },
      weekNumbers: true,
      eventDrop: eventDropNow,
    });


    const loadListWorkSchedule = () => {
      zaloRepository.getListWorkSchedule()
          .then((response) => {
            listWorkSchedule.value = response.data;
            calendarOptions.value.events = [];
            listWorkSchedule.value.forEach(x => {
              calendarOptions.value.events.push({ title: x.fullName, date: x.timeString });
            });
            listSelect.value = [];
            listWorkSchedule.value.forEach(x => {
              if(x.timeString == date.value)
              {
                const workScheduleDetail = ref({} as WorkScheduleDetail);
                workScheduleDetail.value.fullName = x.fullName;
                workScheduleDetail.value.id = x.id;
                listCustomer.value.forEach(y => {
                  if(y.id == x.zaloId)  workScheduleDetail.value.imageLink = y.imageUrl;
                });
                listSelect.value.push(workScheduleDetail.value);
              }
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách công dân',
              life: 2000
            });
          });
    }
    const listCustomer = ref([] as ListCustomer[]);

    const loadListMember = () => {
      zaloRepository.getListCustomer()
          .then((response) => {
            listCustomer.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách công dân',
              life: 2000
            });
          });
    }
    loadListMember();
    loadListWorkSchedule();
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };
    const deleteWorkSchedule = (id) => {
      zaloRepository.deleteWorkSchedule(id)
          .then((response) => {
            toast.add({
              severity: 'warn',
              summary: 'Xoá',
              detail:'Xoá nhân viên ra khỏi lịch thành công',
              life: 3000
            });
            listWorkSchedule.value = [];
            loadListMember();
            loadListWorkSchedule();

            console.log("@@@@@############ debug listSelect: " + JSON.stringify(listSelect.value));
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách công dân',
              life: 2000
            });
          });
    }

    const addWorkSchedule = (id) => {
      const workSchedule = ref({} as WorkSchedule);

      listCustomer.value.forEach(x => {
        if(x.idMember == id){
          workSchedule.value.fullName = x.name;
          workSchedule.value.zaloId = x.id;
          workSchedule.value.timeString = date.value
        }
      })
      zaloRepository.addWorkSchedule(workSchedule.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thêm lịch',
              detail:'Thêm nhân viên vào lịch thành công',
              life: 3000
            });
            // listWorkSchedule.value = [];
            console.log("@@@@@############ debug addWorkSchedule listWorkSchedule: " + JSON.stringify(listWorkSchedule.value));
            loadListMember();
            loadListWorkSchedule();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách công dân',
              life: 2000
            });
          });
    }

    return {
      calendarOptions,
      workSchedule,
      listCustomer,
      listSelect,
      filters,
      clearFilter,
      initFilters,
      deleteWorkSchedule,
      addWorkSchedule,
      checkSelect,
      customer,
      date,
      dateString
    }
  }
}
