import Repository from './Repository'
import { AxiosResponse } from 'axios';
import {Message} from "@/models/message";
import {MessageWithAttachment} from "@/models/messageWithAttachment";
import Province from "@/models/province.models";
import {ListImage} from "@/models/listImage";
import {RegisterServiceUpdate} from "@/models/registerServiceUpdate";
import {Team} from "@/models/team.models";
import {Employee} from "@/models/employee";
import {EmployeeCreate} from "@/models/employeeCreate";
import {EmployeeUpdate} from "@/models/employeeUpdate";
import {GroupCreate} from "@/models/groupCreate";
import {ImportTax} from "@/models/importTax";
import {TaxDetail} from "@/models/taxDetail";
import {CustomerUpdate} from "@/models/customerUpdate";
import {ManagerFeedbackCreate} from "@/models/managerFeedbackCreate";
import {WorkSchedule} from "@/models/workSchedule";
import {ManagerCheckinCreate} from "@/models/managerCheckinCreate";

const resource = '/webhook'

export default new class {
  sendMessage(mess: Message): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/SendMessage`, mess);
  }
  sendMessageWithAttachmentList(mess: MessageWithAttachment): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/SendMessageWithAttachment`, mess);
  }
  getListCustomer(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listCustomer`);
  }
  getProvince(): Promise<AxiosResponse<Province[]>>{
    return Repository.get<Province[]>(`${resource}/listProvince`);
  }
  getListImage(): Promise<AxiosResponse<ListImage[]>>{
    return Repository.get<ListImage[]>(`${resource}/listImage`);
  }
  getListRegister(timeStart: any, timeEnd: any, kind: any): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listRegisterService/${timeStart}/${timeEnd}/${kind}`);
  }
  deleteImageFile(list: ListImage[]): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/deleteImage`, list);
  }
  updateRegisterService(registerServiceUpdate: RegisterServiceUpdate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/updateRegisterService`, registerServiceUpdate);
  }
  registerServiceById(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/registerService/${id}`);
  }
  createEmployee(employeeCreate: EmployeeCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createemployee`, employeeCreate);
  }
  updateEmployee(employeeUpdate: EmployeeUpdate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/updateemployee`, employeeUpdate);
  }
  deleteEmployee(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteemployee/${id}`);
  }
  getListEmployee(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getlistemployee`);
  }
  employeeById(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getemployeebyid/${id}`);
  }
  getListCustomerWithOutEmployee(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listCustomerWithOutEmployee`);
  }
  getListWard(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListWard`);
  }
  getListCivilGroup(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListCivilGroup`);
  }
  getListCivilGroupWithPermission(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListCivilGroupWithPermission`);
  }
  createNewGroup(groupCreate: GroupCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createGroup`, groupCreate);
  }
  deleteGroup(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteGroup/${id}`);
  }
  listGroup(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listGroup`);
  }
  addMemberToGroup(idCustomer: number, idGroup: number): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/addmember/${idCustomer}/${idGroup}`);
  }
  getListMemberInGroup(idGroup: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getListMemberInGroup/${idGroup}`);
  }
  deleteMember(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteMember/${id}`);
  }
  resendMessageTax(list: TaxDetail[]): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/ResendMessageTax`, list);
  }
  getListCustomerDetail(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listCustomerDetail`);
  }
  getCustomerById(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/customer/${id}`);
  }
  updateCustomerByUser(customerUpdate: CustomerUpdate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/updateCustomerByUser`, customerUpdate);
  }
  addManagerFeedback(managerFeedbackCreate: ManagerFeedbackCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createManagerFeedback`, managerFeedbackCreate);
  }
  getListManagerFeedback(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listManagerFeedback`);
  }
  deleteManagerFeedback(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteManagerFeedback/${id}`);
  }
  getListWorkSchedule(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listWorkSchedule`);
  }
  addWorkSchedule(workSchedule: WorkSchedule): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createWorkSchedule`, workSchedule);
  }
  deleteWorkSchedule(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteWorkSchedule/${id}`);
  }
  addManagerOfCheckin(managerCheckinCreate: ManagerCheckinCreate): Promise<AxiosResponse<any>>{
    return Repository.post<any>(`${resource}/createManagerOfCheckin`, managerCheckinCreate);
  }
  getListManagerOfCheckin(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listManagerOfCheckin`);
  }
  deleteManagerOfCheckin(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/deleteManagerOfCheckin/${id}`);
  }
}
